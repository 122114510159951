import { MultimediaType } from '@/features/map/map.type';
import { ActionType } from '../../types/actionType';
import { Action } from './actions';

type State = {
	loading?: number;
	typeMedia?: MultimediaType;
};

const initialState: State = {
	loading: undefined,
	typeMedia: undefined,
};

const reducer = (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case ActionType.MULTIMEDIA_REQUEST_LOADING:
			return { ...state, ...action.payload };
		case ActionType.CLEAN_REDUCER:
		case ActionType.LOG_OUT:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
