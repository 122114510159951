import { Locale, translate } from '@optimusgps/optimus-intl';
import en_US from 'antd/lib/locale-provider/en_US'; //EN
import es_ES from 'antd/lib/locale-provider/es_ES'; //ES
import EventEmmiter from 'wolfy87-eventemitter';

export const CONTENT_OVERFLOW_HEIGHT = 79;
// regex
export const REGEX = {
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	username: /^([.@_A-Za-z0-9+]+)*$/,
	number: /^[0-9]+$/,
	imei: /^[0-9]{15}$/,
	alphaNumeric: /^[a-zA-Z0-9]*$/,
	phone: /^(\+?){1}[0-9\s]{1,20}$/,
	iccid: /^\d{18,20}$/,
	eid: /^\d{32}$/,
	usaPhone: /^[0-9\s]{10}$/,
	pin: /^[a-zA-Z0-9]+[-_a-zA-z0-9]+$/,
	password: /(?=^.{6,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
	areaCode: /^\+\d+$/,
	price: /^price_[a-zA-Z0-9]+$/,
};

export const eventEmmiter = new EventEmmiter();

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const IS_PRODUCTION = ENVIRONMENT === 'production';
export const OPTIMUS_TRACKING_COMPANY_ID_PROD = 4;
export const OPTIMUS_TRACKING_COMPANY_ID_DEV = 3;
export const OPTIMUS_TRACKING_COMPANY_ID = IS_PRODUCTION
	? OPTIMUS_TRACKING_COMPANY_ID_PROD
	: OPTIMUS_TRACKING_COMPANY_ID_DEV;

export const SIREN = {
	SIREN_ON: 'Siren On',
	SIREN_OFF: 'Siren Off',
};
export const INTEREST_TYPE = {
	POINT: 'POINT',
	LINE: 'LINE',
};

export const ALERT_SOUNDS = {
	TEMPORAL: 0,
	PERSISTANT: 1,
	SIREN: 2,
};

// Languages
export const locale = {
	en_US: {
		language: en_US,
		intl: 'en',
		messages: Locale.en,
	},
	es_ES: {
		language: es_ES,
		intl: 'es',
		messages: Locale.es,
	},
};

export const MAP_TYPE = {
	ROADMAP: 'roadmap',
	TERRAIN: 'terrain',
	SATELLITE: 'satellite',
	HYBRID: 'hybrid',
};

export const WIDGETS_ENUM = {
	EVENT_WIDGET: 1,
	SPEED_WIDGET: 2,
	UNITS_STATUS_WIDGET: 3,
	UNITS_STATUS_DETAIL_WIDGET: 4,
	TEMPERATURE_WIDGET: 5,
	BATTERY_LEVEL_WIDGET: 6,
	MAINTENANCE_WIDGET: 7,
	POI_WIDGET: 8,
	FUEL_SENSORS: 9,
};

export const WIDGETS = [
	{
		id: WIDGETS_ENUM.EVENT_WIDGET,
		type: 'TIME_LINE',
		widgetName: 'eventsTimeline',
		height: '600px',
	},
	{ id: WIDGETS_ENUM.SPEED_WIDGET, widgetName: 'speedTop5' },
	{ id: WIDGETS_ENUM.UNITS_STATUS_WIDGET, widgetName: 'unitStatus' },
	{
		id: WIDGETS_ENUM.EVENT_WIDGET,
		type: 'LIST',
		filterOptions: [19, 20],
		geofence: true,
		widgetName: 'eventGeofence',
	},
	{
		id: WIDGETS_ENUM.UNITS_STATUS_DETAIL_WIDGET,
		widgetName: 'unitStatusDetail',
	},
	{
		id: WIDGETS_ENUM.EVENT_WIDGET,
		type: 'TABLE',
		widgetName: 'eventDetails',
	},
	{ id: WIDGETS_ENUM.TEMPERATURE_WIDGET, widgetName: 'temperatureEvent' },
	{ id: WIDGETS_ENUM.BATTERY_LEVEL_WIDGET, widgetName: 'batteryLevel' },
	{
		id: WIDGETS_ENUM.MAINTENANCE_WIDGET,
		widgetName: 'preventiveMaintenance',
		width: '700px',
	},
	{ id: WIDGETS_ENUM.POI_WIDGET, widgetName: 'poi' },
	{ id: WIDGETS_ENUM.FUEL_SENSORS, widgetName: 'fuel' },
];

export const ROLES = {
	MASTER: 1,
	SERVICE_PROVIDER: 2,
	CLIENT_ADMIN: 3,
	MAP: 4,
	REPORTS: 5,
	GROUP: 6,
	CUSTOMER_SUPPORT: 7,
	VIEW_ALL_DEVICES: 8,
	NOTIFICATION_ADMIN: 9,
	MAINTENANCE: 10,
	GEOFENCE: 11,
	POI: 12,
	ROUTE_MONITOR: 13,
	COMMAND: 14,
	MANAGE_DEVICE_INFO: 15,
	GOOGLE_EARTH: 16,
	TEMPERATURE_REPORT: 17,
	SHARE_EXTERNAL: 18,
	GEOFENCE_REPORT: 19,
	MAINTENANCE_REPORT: 21,
	FUEL_REPORT: 22,
	COMMAND_REPORT: 23,
	POI_REPORT: 24,
	LOGIN_HISTORY_REPORT: 25,
	MANAGE_SENSORS: 26,
	MONITOR_DEVICE_INFO: 28,
	SUKARNE: 29,
	CAE: 32,
	FUEL_CONTROL: 36,
	MANAGE_DRIVERS: 37,
	ACCOUNT_MANAGER: 38,
	MANAGE_USERS: 39,
	INTEGRATIONS: 40,
	TRUST_PILOT_MANAGEMENT: 41,
	SUBSCRIPTION_MANAGEMENT: 42,
	CYCLE_MONITORING: 43,
	OPTIMUS_MANAGEMENT: 44,
	BING_SPEED: 45,
	COMMAND_MASSIVE: 46,
	COMMAND_PROGRAMMED: 47,
	COMMAND_AUTOMATIC: 48,
	DASHBOARD: 49,
	TRACKING_ROUTING: 50,
	TRACKING: 51,
	DEVELOPER: 52,
	GEOFENCE_SELECT_ALL: 53,
	IMPORT_TASKS_BY_COORDINATES: 54,
	RALENTI_REPORT: 56,
	VIDEO_CAMERA: 58,
	IDLE_REPORT: 59,
	LIVE_VIDEO: 60,
	TASK_ROUTE_REPORT: 61,
};

export const ICON_IDS = {
	PERSON_ICON_ID: 24,
	GPS_ID: 29,
	MARKER_ID: 30,
	FARMTRUCK: 32,
	FLATBEDTRUCK: 33,
	STATIONARYTANK: 34,
	INDUSTRIALMACHINE: 35,
};

export const ERROR_CODES = {
	LINE_NOT_FOUND: 'LINE_NOT_FOUND',
};
export const SIM_CARD_STATUS = {
	ACTIVATE: 'ACTIVATE',
	DEACTIVATE: 'DEACTIVATE',
};

export const ICON_IDS_WITH_NO_AZIMUTH = [
	ICON_IDS.PERSON_ICON_ID,
	ICON_IDS.GPS_ID,
	ICON_IDS.MARKER_ID,
	ICON_IDS.FARMTRUCK,
	ICON_IDS.FLATBEDTRUCK,
	ICON_IDS.STATIONARYTANK,
	ICON_IDS.INDUSTRIALMACHINE,
];

export const ROLES_SECTIONS = {
	GENERAL: 'GENERAL',
	MAP: 'MAP',
	REPORTS: 'REPORTS',
	ADMINISTRATION: 'ADMINISTRATION',
	COMMAND: 'COMMAND',
};

export const ROLE_DEPENDENCIES = {
	[ROLES_SECTIONS.GENERAL]: [
		ROLES.MASTER,
		ROLES.ACCOUNT_MANAGER,
		ROLES.SERVICE_PROVIDER,
		ROLES.CLIENT_ADMIN,
		ROLES.DASHBOARD,
		ROLES.GROUP,
		ROLES.GOOGLE_EARTH,
		ROLES.VIEW_ALL_DEVICES,
		ROLES.NOTIFICATION_ADMIN,
		ROLES.MAINTENANCE,
		ROLES.MANAGE_SENSORS,
		ROLES.SUKARNE,
		ROLES.CAE,
		ROLES.FUEL_CONTROL,
		ROLES.MANAGE_DRIVERS,
		ROLES.MANAGE_USERS,
		ROLES.INTEGRATIONS,
		ROLES.TRUST_PILOT_MANAGEMENT,
		ROLES.SUBSCRIPTION_MANAGEMENT,
		ROLES.OPTIMUS_MANAGEMENT,
		ROLES.TRACKING_ROUTING,
		ROLES.TRACKING,
		ROLES.IMPORT_TASKS_BY_COORDINATES,
		ROLES.VIDEO_CAMERA,
		ROLES.LIVE_VIDEO,
	],
	[ROLES_SECTIONS.COMMAND]: [
		ROLES.COMMAND_REPORT,
		ROLES.COMMAND_MASSIVE,
		ROLES.COMMAND_PROGRAMMED,
		ROLES.COMMAND_AUTOMATIC,
	],
	[ROLES_SECTIONS.MAP]: [
		ROLES.GEOFENCE,
		ROLES.POI,
		ROLES.ROUTE_MONITOR,
		ROLES.MANAGE_DEVICE_INFO,
		ROLES.SHARE_EXTERNAL,
		ROLES.MONITOR_DEVICE_INFO,
		ROLES.CYCLE_MONITORING,
	],
	[ROLES_SECTIONS.ADMINISTRATION]: [ROLES.CUSTOMER_SUPPORT],
	[ROLES_SECTIONS.REPORTS]: [
		ROLES.GEOFENCE_REPORT,
		ROLES.MAINTENANCE_REPORT,
		ROLES.FUEL_REPORT,
		ROLES.POI_REPORT,
		ROLES.LOGIN_HISTORY_REPORT,
		ROLES.TEMPERATURE_REPORT,
		ROLES.BING_SPEED,
		ROLES.RALENTI_REPORT,
		ROLES.IDLE_REPORT,
		ROLES.TASK_ROUTE_REPORT,
	],
};

export const SPECIAL_ROLES = [
	ROLES.MAP,
	ROLES.REPORTS,
	ROLES.GROUP,
	ROLES.VIEW_ALL_DEVICES,
	ROLES.NOTIFICATION_ADMIN,
	ROLES.MAINTENANCE,
	ROLES.GEOFENCE,
	ROLES.POI,
	ROLES.ROUTE_MONITOR,
	ROLES.COMMAND,
	ROLES.MANAGE_DEVICE_INFO,
	ROLES.GOOGLE_EARTH,
	ROLES.TEMPERATURE_REPORT,
	ROLES.SHARE_EXTERNAL,
	ROLES.GEOFENCE_REPORT,
	ROLES.MAINTENANCE_REPORT,
	ROLES.FUEL_REPORT,
	ROLES.COMMAND_REPORT,
	ROLES.POI_REPORT,
	ROLES.LOGIN_HISTORY_REPORT,
	ROLES.MONITOR_DEVICE_INFO,
	ROLES.SUKARNE,
	ROLES.CAE,
	ROLES.FUEL_CONTROL,
	ROLES.MANAGE_DRIVERS,
	ROLES.MANAGE_USERS,
	ROLES.INTEGRATIONS,
	ROLES.TRUST_PILOT_MANAGEMENT,
	ROLES.SUBSCRIPTION_MANAGEMENT,
	ROLES.ACCOUNT_MANAGER,
	ROLES.BING_SPEED,
	ROLES.COMMAND_MASSIVE,
	ROLES.COMMAND_PROGRAMMED,
	ROLES.COMMAND_AUTOMATIC,
	ROLES.DASHBOARD,
	ROLES.TRACKING_ROUTING,
	ROLES.TRACKING,
	ROLES.CYCLE_MONITORING,
	ROLES.IMPORT_TASKS_BY_COORDINATES,
	ROLES.VIDEO_CAMERA,
	ROLES.RALENTI_REPORT,
	ROLES.LIVE_VIDEO,
	ROLES.TASK_ROUTE_REPORT,
];

export const MASTER_ROLES = [ROLES.CAE, ROLES.MANAGE_SENSORS];

export const EVENT_ENUM = {
	POSITION: 0,
	VEHICLE_MOVING: 1,
	ENGINE_OFF: 2,
	SOS: 3,
	TOW: 4,
	GPS_POLL: 5,
	INFO: 6,
	GPS_ON: 7,
	GPS_OFF: 8,
	ENGINE_ON: 9,
	GPS_BATT_LOW: 10,
	GPS_SIGNAL_LOST: 11,
	GPS_SIGNAL_UP: 12,
	ENERGY_ON: 13,
	ENERGY_OFF: 14,
	COLLISION: 15,
	VEHICLE_IDLE: 16,
	IS_NEAR_ROUTE: 17,
	LEFT_THE_ROUTE: 18,
	GEOFENCE_IN: 19,
	GEOFENCE_OUT: 20,
	GEOFENCE_MIN_SPEED: 21,
	SPEEDING: 22,
	OUT_OF_SCHEDULE: 23,
	MAINTENANCE: 24,
	ALERT_IN_TRANSIT: 25,
	TANK_FILL: 27,
	KEY_IN: 28,
	ANALOG_INPUT_1_C: 29,
	ANALOG_INPUT_1_D: 30,
	ANALOG_INPUT_2_C: 31,
	ANALOG_INPUT_2_D: 32,
	ANALOG_INPUT_3_C: 33,
	ANALOG_INPUT_3_D: 34,
	DIGITAL_INPUT_1_C: 35,
	DIGITAL_INPUT_1_D: 36,
	DIGITAL_INPUT_2_C: 37,
	DIGITAL_INPUT_2_D: 38,
	DIGITAL_INPUT_3_C: 39,
	DIGITAL_INPUT_3_D: 40,
	DIGITAL_INPUT_4_C: 41,
	DIGITAL_INPUT_4_D: 42,
	TEMPERATURE_ALERT: 43,
	POLLING: 44,
	COMMAND_RECEIVED: 45,
	VEHICLE_PARKED: 46,
	SENSOR_DISCONNECT: 47,
	SENSOR_CONNECT: 48,
	VEHICLE_FALL: 49,
	GPS_BATTERY_CHARGING: 50,
	GPS_BATTERY_NOT_CHARGING: 51,
	GSM_JAMMING_DETECTION: 52,
	OBD_OFF: 53,
	OBD_ON: 54,
	HARD_BRAKING: 55,
	HARD_ACCELERATION: 56,
	HARSH_CORNERING: 57,
	HARD_BRAKING_TURN: 58,
	HARD_ACCELERATION_TURN: 59,
	UNKNOWN_HARD_BEHAVIOR: 60,
	JAMMING_DETECTION: 61,
	BATTERY_DISCONNECT_COUNTER: 62,
	OPEN_BOLT: 63,
	CLOSE_BOLT: 64,
	DOOR_OPENED: 65,
	DOOR_CLOSED: 66,
	OPEN_PACKAGE: 67,
	CLOSE_PACKAGE: 68,
	PHOTO_REPORT: 69,
	DECOUPLED: 70,
	PARKING_TIME_LIMIT: 71,
	ENGINE_HIGH_TEMP: 72,
	BEHAVIOR_MAX_SPEED_LIMIT: 73,
	HARSHJAMM_DETECTION: 74,
	OBD_INFO: 78,
	OPEN_BACK: 79,
	STRING_CUT: 80,
	LOCK_OPEN: 81,
	LOCK_CLOSE: 82,
	SWIPE_AUTH_CARD: 83,
	UNAUTHORIZED_RFID: 84,
	VIBRATION_ALARM: 85,
	WRONG_PASSWORD: 86,
	DWELL: 87,
	IRREGULAR_FUEL_CONSUMPTION: 91,
	VIDEO_LOST: 92,
	LENS_COVERED: 93,
	STORAGE_MALFUNCTION: 94,
	NO_DRIVER: 95,
	PHONE_CALLING: 96,
	DRIVER_SMOKING: 97,
	DRIVER_DISTRACTED: 98,
	LANE_DEPARTURE_WARNING: 99,
	FORWARD_COLLISION_WARNING: 100,
	HEADWAY_MONITORING_WARNING: 101,
	PEDESTRIAN_COLLISION_WARNING: 102,
	TRIP_STARTED: 104,
	EXIT_DELIVERY_ROUTE: 105,
	PARKING_SAFEGUARD: 106,
	MANUAL_RECORD: 107,
	SCHEDULE: 108,
	ROUTE_APP_ON: 109,
	ROUTE_APP_OFF: 110,
	CAMERA_FAULT: 111,
	MEMORY_CARD_FAULT: 112,
	DATA_USAGE: 113,
};

export const EVENT_WITHOUT_ICONS = [
	EVENT_ENUM.GPS_POLL,
	EVENT_ENUM.INFO,
	EVENT_ENUM.GPS_ON,
	EVENT_ENUM.GPS_OFF,
	EVENT_ENUM.ENGINE_ON,
	EVENT_ENUM.ENERGY_ON,
	EVENT_ENUM.VEHICLE_IDLE,
	EVENT_ENUM.IS_NEAR_ROUTE,
	EVENT_ENUM.LEFT_THE_ROUTE,
	EVENT_ENUM.GEOFENCE_MIN_SPEED,
	EVENT_ENUM.ALERT_IN_TRANSIT,
	EVENT_ENUM.KEY_IN,
	EVENT_ENUM.ANALOG_INPUT_1_C,
	EVENT_ENUM.ANALOG_INPUT_1_D,
	EVENT_ENUM.ANALOG_INPUT_2_C,
	EVENT_ENUM.ANALOG_INPUT_2_D,
	EVENT_ENUM.ANALOG_INPUT_3_C,
	EVENT_ENUM.ANALOG_INPUT_3_D,
	EVENT_ENUM.DIGITAL_INPUT_1_D,
	EVENT_ENUM.DIGITAL_INPUT_2_C,
	EVENT_ENUM.DIGITAL_INPUT_2_D,
	EVENT_ENUM.DIGITAL_INPUT_3_C,
	EVENT_ENUM.DIGITAL_INPUT_3_D,
	EVENT_ENUM.DIGITAL_INPUT_4_C,
	EVENT_ENUM.DIGITAL_INPUT_4_D,
	EVENT_ENUM.POLLING,
	EVENT_ENUM.COMMAND_RECEIVED,
	EVENT_ENUM.SENSOR_DISCONNECT,
	EVENT_ENUM.SENSOR_CONNECT,
	EVENT_ENUM.VEHICLE_FALL,
	EVENT_ENUM.OBD_OFF,
	EVENT_ENUM.OBD_ON,
	EVENT_ENUM.HARD_ACCELERATION,
	EVENT_ENUM.HARD_BRAKING_TURN,
	EVENT_ENUM.HARD_ACCELERATION_TURN,
	EVENT_ENUM.UNKNOWN_HARD_BEHAVIOR,
	EVENT_ENUM.BATTERY_DISCONNECT_COUNTER,
	EVENT_ENUM.OPEN_BOLT,
	EVENT_ENUM.CLOSE_BOLT,
	EVENT_ENUM.DOOR_OPENED,
	EVENT_ENUM.DOOR_CLOSED,
	EVENT_ENUM.OPEN_PACKAGE,
	EVENT_ENUM.CLOSE_PACKAGE,
	EVENT_ENUM.DECOUPLED,
	EVENT_ENUM.PARKING_TIME_LIMIT,
	EVENT_ENUM.BEHAVIOR_MAX_SPEED_LIMIT,
	EVENT_ENUM.OBD_INFO,
	EVENT_ENUM.OPEN_BACK,
	EVENT_ENUM.STRING_CUT,
	EVENT_ENUM.SWIPE_AUTH_CARD,
	EVENT_ENUM.UNAUTHORIZED_RFID,
	EVENT_ENUM.VIBRATION_ALARM,
	EVENT_ENUM.WRONG_PASSWORD,
	EVENT_ENUM.DWELL,
];

//  TO-DO Add support to this events: SPEEDING, GEOFENCE_IN, GEOFENCE_OUT
export const REQUEST_VIDEO_BY_EVENTS = [
	EVENT_ENUM.ENGINE_ON,
	EVENT_ENUM.VEHICLE_MOVING,
	EVENT_ENUM.ENGINE_OFF,
	EVENT_ENUM.VEHICLE_PARKED,
	EVENT_ENUM.ENERGY_OFF,
	EVENT_ENUM.COLLISION,
	EVENT_ENUM.HARD_ACCELERATION,
	EVENT_ENUM.HARD_BRAKING,
	EVENT_ENUM.HARD_BRAKING_TURN,
	EVENT_ENUM.HARSH_CORNERING,
	EVENT_ENUM.MANUAL_RECORD,
	EVENT_ENUM.SOS,
	EVENT_ENUM.PARKING_SAFEGUARD,
];

export const BEHAVIOR_ENUM = {
	ENTER: 0,
	LEAVE: 1,
	ENTER_AND_LEAVE: 2,
	REMAIN_IN_AREA: 3,
	SPEED_CONTROL: 4,
};

export const reportsEnum = {
	DEFAULT: 'BING',
	DRIVING: 'DRIVING',
	PARKING: 'PARKING',
	SPEEDING: 'SPEEDING',
	MOVEMENT: 'MOVEMENT',
	MAINTENANCELOG: 'MAINTENANCELOG',
	GASCONSUMPTION: 'GASCONSUMPTION',
	VISITEDPOI: 'VISITEDPOI',
	COMMANDHISTORY: 'COMMANDHISTORY',
	LOGIN_HISTORY: 'LOGIN_HISTORY',
	UNITS_PER_CLIENT: 'UNITS_PER_CLIENT',
	GEOFENCE: 'GEOFENCE',
	PHOTOS: 'PHOTOS',
	TEMPERATURE: 'TEMPERATURE',
	EVENTS: 'EVENTS',
	CURRENT_STATE: 'CURRENT_STATE',
	DISTANCE: 'DISTANCE',
	MAINTENANCE_CURRENT_STATE: 'MAINTENANCE_CURRENT_STATE',
	FUELBILL: 'FUELBILL',
	DEVICES: 'DEVICES',
	IDLE: 'IDLE',
	INACTIVITY: 'INACTIVITY',
	RALENTI: 'RALENTI',
	TASK_ROUTES: 'TASK_ROUTES',
};
export const REPORT_TITLE = {
	DRIVING: translate('drivingReport'),
	PARKING: translate('parking'),
	SPEEDING: translate('speedingReport'),
	MOVEMENT: translate('movement'),
	MAINTENANCELOG: translate('maintenanceLogReport'),
	GASCONSUMPTION: translate('gasConsumptionReport'),
	VISITEDPOI: translate('poi'),
	COMMANDHISTORY: translate('commandHistoryReport'),
	LOGIN_HISTORY: translate('loginHistoryReport'),
	UNITS_PER_CLIENT: translate('unitsPerClientReport'),
	GEOFENCE: translate('geofencesReport'),
	PHOTOS: translate('photosReport'),
	TEMPERATURE: translate('temperatureReport'),
	EVENTS: translate('eventsReport'),
	CURRENT_STATE: translate('currentStateReport'),
	DISTANCE: translate('distance'),
	MAINTENANCE_CURRENT_STATE: translate('maintenanceCurrentStateReport'),
	FUELBILL: translate('fuelBillTitle'),
	DEVICES: translate('devicesByCompanyTitle'),
	IDLE: translate('idle'),
	INACTIVITY: translate('inactivity'),
	RALENTI: translate('ralentiReport'),
	TASK_ROUTES: translate('taskRoutesReport'),
};
export const TYPE_OF_PARAMETERS = {
	UNITS: 1,
	EVENTS: 2,
	BEHAVIORS: 3,
	POI_BEHAVIORS: 4,
	SPEED_RANGE: 5,
	INTERVAL: 6,
	CLIENTS: 7,
	SINGLESHEET: 8,
	MAINTENANCE: 9,
};
export const PERIODICITY = {
	DAILY: {
		value: 'DAILY',
		message: translate('daily'),
		isActive: true,
	},
	WEEKLY: {
		value: 'WEEKLY',
		message: translate('weekly'),
		isActive: true,
	},
	BIWEEKLY: {
		value: 'BIWEEKLY',
		message: translate('biWeekly'),
		isActive: true,
	},
	MONTHLY: {
		value: 'MONTHLY',
		message: translate('monthly'),
		isActive: true,
	},
};

export const TYPE_OF_FILE_TO_EXPORT = {
	EXCEL: 1,
};

export const AUTOMATIC_REPORT_ACTIVE_STATUS = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};

export const AUTOMATIC_REPORTS = [
	{
		type: reportsEnum.DRIVING,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS, TYPE_OF_PARAMETERS.SINGLESHEET],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.PARKING,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.SPEEDING,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS, TYPE_OF_PARAMETERS.SPEED_RANGE],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.MOVEMENT,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},

	{
		type: reportsEnum.MAINTENANCELOG,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.GASCONSUMPTION,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.VISITEDPOI,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS, TYPE_OF_PARAMETERS.POI_BEHAVIORS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.COMMANDHISTORY,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.LOGIN_HISTORY,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.INACTIVE,
		paramaterList: [],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.UNITS_PER_CLIENT,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.INACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.CLIENTS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.GEOFENCE,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS, TYPE_OF_PARAMETERS.BEHAVIORS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.PHOTOS,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.INACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.TEMPERATURE,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.EVENTS,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS, TYPE_OF_PARAMETERS.EVENTS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.CURRENT_STATE,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.DISTANCE,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	{
		type: reportsEnum.MAINTENANCE_CURRENT_STATE,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.SINGLESHEET, TYPE_OF_PARAMETERS.MAINTENANCE],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
	// {
	// 	type: reportsEnum.RALENTI,
	// 	isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.ACTIVE,
	// 	paramaterList: [TYPE_OF_PARAMETERS.UNITS],
	// 	exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	// },
	{
		type: reportsEnum.TASK_ROUTES,
		isActive: AUTOMATIC_REPORT_ACTIVE_STATUS.INACTIVE,
		paramaterList: [TYPE_OF_PARAMETERS.UNITS],
		exportTypes: [TYPE_OF_FILE_TO_EXPORT.EXCEL],
	},
];

export const REPORT_STATUS = {
	notProcessed: 'NOT_PROCESSED',
	onQueue: 'ON_QUEUE',
	inProgress: 'IN_PROGRESS',
	fail: 'FAIL',
};

export const selectedMapTrail = {
	NONE: -1,
	All: 0,
	LAST: 1,
};

export const TANKS_TYPE = {
	RECTANGULAR: 1,
	CYLINDRICAL: 2,
	IRREGULAR: 3,
	PERCENTAGE: 4,
	VOLUME: 5,
	INTERPOLATION: 6,
};

export const PERIODICITY_TYPE = {
	DAY: 1,
	WEEK: 2,
	MONTH: 3,
};

export const weekDays = {
	0: 'sunday',
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
};

export const defaultSchedule = {
	0: [],
	1: [],
	2: [],
	3: [],
	4: [],
	5: [],
	6: [],
};

export const loginEnum = {
	1: 'LOGIN_SUCCESS',
	2: 'LOGIN_FAILED',
	3: 'LOGOUT_SUCCESS',
	4: 'LOGOUT_FAILED',
	5: 'RESTORE_PASS',
};

export const impersonateEnum = {
	1: 'IMPERSONATE_SUCCESS',
	2: 'IMPERSONATE_FAILED',
	3: 'REVERT_IMPERSONATE_SUCCESS',
	4: 'REVERT_IMPERSONATE_FAILED',
};

export const MAINTENANCE_TYPE = {
	DISTANCE: 0,
	TIME: 1,
	PERIODICITY: 2,
};

export const MAINTENANCE_TYPE_KEY = {
	[MAINTENANCE_TYPE.DISTANCE]: 'distance',
	[MAINTENANCE_TYPE.TIME]: 'time',
	[MAINTENANCE_TYPE.PERIODICITY]: 'periodic',
};
export const RESET_MAINTENANCE_TYPE = {
	ACCUMULATED: 'accumulated',
	DATE: 'date',
	HOUR: 'hour',
};

export const DIGITAL_INPUTS = [
	EVENT_ENUM.DIGITAL_INPUT_1_C,
	EVENT_ENUM.DIGITAL_INPUT_1_D,
	EVENT_ENUM.DIGITAL_INPUT_2_C,
	EVENT_ENUM.DIGITAL_INPUT_2_D,
	EVENT_ENUM.DIGITAL_INPUT_3_C,
	EVENT_ENUM.DIGITAL_INPUT_3_D,
	EVENT_ENUM.DIGITAL_INPUT_4_C,
	EVENT_ENUM.DIGITAL_INPUT_4_D,
];
export const GEOFENCE_EVENTS = [
	EVENT_ENUM.GEOFENCE_IN,
	EVENT_ENUM.GEOFENCE_MIN_SPEED,
	EVENT_ENUM.GEOFENCE_OUT,
	EVENT_ENUM.DWELL,
];

export const SPEED_RANGES_CONFIGURATION = [
	{ minSpeed: 0, maxSpeed: 50, color: '#4CAF50' },
	{ minSpeed: 51, maxSpeed: 100, color: '#FF9800' },
	{ minSpeed: 101, maxSpeed: 200, color: '#9C27B0' },
];

export const POI_TABLE_TYPES = {
	VISITED: '1',
	UNVISITED: '2',
	OFFVISIT: '3',
};

export const VIDEO_STATUS = {
	LOADING: 1,
	LIVE: 2,
	OFF: 3,
	EVENT: 4,
	EVENT_NOT_READY: 5,
};

export const VIDEO_QUALITY = {
	FLUENT: 1,
	STANDARD: 2,
	CLEAR: 3,
};

export const GEOCODINGMETHODS = {
	DISABLE: 0,
	GOOGLE: 1,
	HERE: 2,
};

export const GEOCODINGFORMATS = {
	US: 1,
	HN: 2,
};

export const SENSORS_INPUTS = [1, 2, 3];
export const TRIP_STATUS = {
	PENDING: 0,
	IN_PROGRESS: 1,
	COMPLETED: 2,
	INCOMPLETED: 3,
	CANCEL: 4,
};

export const Driving_Calculation_Method = {
	BY_EVENT: 1,
	BY_TIME: 2,
};

export const REPORT_CONTENT_ENUM = {
	TABLE: 1,
	CHART: 2,
};

export const KEY_TYPE = {
	id: 'id',
	name: 'name',
};

export const MIN_SPEED_DEFAULT = 136;
export const MAX_TEMPERATURE_VALUE = 100;
export const BEHAVIOR_MIN_SPEED = 20;
export const MIN_SPEED = 40;
export const MAX_SPEED = 280;

export const ONE_MINUTE_IN_MILLISECONDS = 60000;

export const ONE_WEEK_IN_SECONDS = 60 * 60 * 24 * 7; // seconds * minutes * hours * days
export const ONE_DAY_IN_HOURS = 24;
export const ONE_WEEK_IN_DAYS = 7;
export const ONE_MONTH_IN_DAYS = 30;

export const MAX_ZINDEX = 1000000;

export const MAX_IMAGE_SIZE_MB = 10;

export const MAX_NEAREST_DISTANCE = 500;

export const REFRESH_TOKEN_ENDPOINT = '/v2/auth/refresh-token';

export const PLACES_TYPE = {
	CIRCLE: 0,
	POLYGON: 1,
	POLYLINE: 2,
	RECTANGLE: 3,
	TEMPORAL: 4,
	MARKER: 5,
};

export const DASH_INDICATORS = {
	TANK: 1,
	THERMOMETER: 2,
	BATTERY: 3,
	SIGNAL: 4,
	BATTERY_VOLTAGE: 5,
	HUMIDITY: 6,
	THERMOMETER_BATTERY_LEVEL: 7,
};

export const DEVICE_STATUS = {
	ACTIVE: 1,
	MAINTENANCE: 2,
	MORA: 3,
	CANCEL: 4,
};

export const COMMANDS_TYPE = {
	DEFAULT: 0,
	POLL_REQUEST_POSITION: 1,
	REBOOT_GPS_UNIT: 2,
	DISABLE_ENGINE: 3,
	ENABLE_ENGINE: 4,
	REPORT_EVERY_2_MIN: 5,
	REPORT_EVERY_1_MIN: 6,
	REPORT_EVERY_3_MIN: 7,
	UNLOCK_DOORS: 8,
	REQUEST_BATTERY_LEVEL: 9,
	REPORT_EVERY_4_MIN: 10,
	REPORT_EVERY_5_MIN: 11,
	REQUEST_PHOTO: 12,
	CANCEL_ALARM: 13,
	LOCK_DOORS: 14,
	TURN_ON_SIREN: 15,
	REPORT_EVERY_10_MIN: 16,
	ENGINE_OFF_AND_SIREN_ON: 17,
	DISABLE_OUTPUTS: 18,
	SIREN_ON: 19,
	EMERGENCY_TRACKING: 20,
	END_EMERGENCY_TRACKING: 21,
	REPORT_EVERY_30_SEC: 22,
	REPORT_EVERY_10_SEC: 23,
	REPORT_EVERY_5_SEC: 24,
	ACTIVATE_JAMMING: 25,
	DEACTIVATE_JAMMING: 26,
	REQUEST_VIDEO: 27,
	REQUEST_VIDEO_BY_EVENT: 28,
	CAMERA_LIVE_CONFIGURATION: 29,
	REQUEST_DASHCAM_PHOTO: 53,
	REQUEST_DASHCAM_PHOTO_BY_EVENT: 54,
};

export const FormulaType = {
	EXCEL: 0,
	CUSTOM: 1,
};

export const INTEGRATIONS = {
	WALMART: { TYPE: 'WALMART', SERVICE: 'walmart', TITLE: 'Walmart Mx' },
	SUTRAN: { TYPE: 'SUTRAN', SERVICE: 'sutran', TITLE: 'Sutran' },
	CONTROLT: { TYPE: 'CONTROLT', SERVICE: 'controlt', TITLE: 'ControlT' },
	SECURITAS: { TYPE: 'SECURITAS', SERVICE: 'securitas', TITLE: 'Securitas' },
	OSINERGMIN: { TYPE: 'OSINERGMIN', SERVICE: 'osinergmin', TITLE: 'Osinergmin' },
	SIPCOP: { TYPE: 'SIPCOP', SERVICE: 'sipcop', TITLE: 'SipCop' },
	UNIGIS: { TYPE: 'UNIGIS', SERVICE: 'unigis', TITLE: 'Unigis' },
	OPTIMUS: { TYPE: 'OPTIMUS', SERVICE: 'optimusapi3p', TITLE: 'Optimus Api 3P' },
	MAERSK: { TYPE: 'MAERSK', SERVICE: 'maersk', TITLE: 'Maersk' },
	KRONH: { TYPE: 'KRONH', SERVICE: 'kronh', TITLE: 'Kronh' },
	DRIVIN: { TYPE: 'DRIVIN', SERVICE: 'drivin', TITLE: 'Driv.in' },
	IRON_TRACKING: { TYPE: 'IRON_TRACKING', SERVICE: 'ironTracking', TITLE: 'IronTracking' },
	STARTRACK: { TYPE: 'STARTRACK', SERVICE: 'startrack', TITLE: 'Startrack' },
	SIPCOP_POLICIAL: { TYPE: 'SIPCOP_POLICIAL', SERVICE: 'sipcopPolicial', TITLE: 'SipCop Policia' },
	ALEPH: { TYPE: 'ALEPH', SERVICE: 'aleph', TITLE: 'Aleph' },
	BIG_RENTALS: { TYPE: 'BIG_RENTALS', SERVICE: 'bigRentals', TITLE: 'Big Rentals' },
	OVER_HAUL: { TYPE: 'OVER_HAUL', SERVICE: 'overhaul', TITLE: 'Overhaul' },
};

export const WEBHOOKS_ACTIONS = {
	add: 'added',
	remove: 'removed',
};

export const SERVICES = {
	SENGRID: 'sendGrid',
	MAIL: 'mailService',
	TWILIO: 'twilio',
	BING: 'bing',
};

export const UnitSystemEnum = {
	CELSIUS: 'ºC',
	FAHRENHEIT: 'ºF',
	RPM: 'RPM',
	LITER: 'L',
	GALLON: 'Gal',
	KILOMETER: 'Km',
	KILOMETER_PER_HOUR: 'Km/h',
	KILOMETER_PER_LITER: 'Km/L',
	MILE: 'Mi',
	MILE_PER_HOUR: 'MPH',
	MILE_PER_GALLON: 'MPG',
	TIME_12_HRS: 'hh:mm:ss A',
	TIME_24_HRS: 'HH:mm:ss',
	DATE_FORMAT_METRIC: 'DD/MM/YYYY',
	DATE_FORMAT_US: 'MM/DD/YYYY',
	CUSTOM_EFFICIENCY: 'CUSTOM',
};

export const COMMAND_EXECUTION_TYPE = {
	DIRECT: 'DIRECT',
	QUEUED: 'QUEUED',
};
export const BING_TYPE = {
	OFF: 'OFF',
	CAR: 'CAR',
	TRUCK: 'TRUCK',
};

export const SUBSCRIPTION_MODE = {
	INDIVIDUAL: 'individual',
	UNIFIED: 'unified',
};

export const MULTIMEDIA_TYPE = {
	VIDEO: 'VIDEO',
	PHOTO: 'IMAGE',
	VIDEO_PHOTO: 'VIDEOIMAGE',
};

export const CAMERA_TYPE = {
	PHOTO: 1,
	VIDEO: 2,
};

export const VIDEO_CAMERA_NAMES = ['Interior', 'Forward', 'CH 1', 'CH 2'];

export const MAINTENANCE_RESET_STATUS = {
	QUEUE: 'QUEUE',
	PROSSESSING: 'PROSSESSING',
};

export const AUTHORIZED_SCHEDULE_ERRORS = {
	OVERLAP_ERROR: 'authorizedScheduleOverlapError',
	SAME_FROM_TO_ERROR: 'authorizedScheduleSameFromToError',
	EMPTY_ERROR: 'authorizedScheduleEmptyError',
	EMPTY_DAY_ERROR: 'authorizedScheduleEmptyDayError',
};

export const API_KEY_STATUS = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
};
