import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { AnnouncementData } from '@/OptimusRoutes/types/announcements.types';

type AnnouncementContextValue = {
	isBannerVisible: boolean;
	announcementData: AnnouncementData | null;
	setPreviewAnnouncement: (data: AnnouncementData) => void;
	cleanAnnouncement: () => void;
};

const AnnouncementContext = React.createContext<AnnouncementContextValue | null>(null);

export const useAnnouncement = (): AnnouncementContextValue => {
	return useContext(AnnouncementContext)!;
};

type Props = {
	children: ReactNode;
};
const AnnouncementProvider = ({ children }: Props) => {
	const [isAnnouncementVisible, setAnnouncementVisible] = useState<boolean>(false);
	const [announcementData, setAnnouncementData] = useState<AnnouncementData | null>(null);

	const setPreviewAnnouncement = (data: AnnouncementData) => {
		setAnnouncementData(data);
		setAnnouncementVisible(true);
	};

	const cleanAnnouncement = () => {
		setAnnouncementData(null);
	};

	const contextValue: AnnouncementContextValue = useMemo(
		() => ({
			isBannerVisible: isAnnouncementVisible,
			announcementData,
			setPreviewAnnouncement,
			cleanAnnouncement,
		}),
		[isAnnouncementVisible, announcementData]
	);

	return <AnnouncementContext.Provider value={contextValue}>{children}</AnnouncementContext.Provider>;
};

export default AnnouncementProvider;
