import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import AnnouncementProvider from '@/OptimusRoutes/pages/Announcements/components/AnnouncementContext';
import LanguagePage from './features/languages/Language';
import { store } from './store/configureStore';
import AxiosInterceptor from './AxiosInterceptor';

const App = () => (
	<Provider store={store}>
		<AxiosInterceptor>
			<AnnouncementProvider>
				<LanguagePage />
			</AnnouncementProvider>
		</AxiosInterceptor>
	</Provider>
);

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
